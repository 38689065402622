<template>
  <div>
    <FeatureHeaderMobile v-if="getDisplaySize() === 'sm' || getDisplaySize() === 'xs' "></FeatureHeaderMobile>
    <FeatureHeader v-if="getDisplaySize() !== 'sm' && getDisplaySize() !== 'xs'"></FeatureHeader>

    <FeatureListMobile v-if="getDisplaySize() === 'sm' || getDisplaySize() === 'xs' "></FeatureListMobile>
    <FeatureList v-if="getDisplaySize() !== 'sm' && getDisplaySize() !== 'xs'"></FeatureList>

    <FeatureListDetailMobile v-if="getDisplaySize() === 'sm' || getDisplaySize() === 'xs' "></FeatureListDetailMobile>
    <FeatureListDetail v-if="getDisplaySize() !== 'sm' && getDisplaySize() !== 'xs'"></FeatureListDetail>

    <FeatureForm class="px-8"></FeatureForm>

    <PersonalSupportMobile v-if="getDisplaySize() === 'sm' || getDisplaySize() === 'xs' "></PersonalSupportMobile>
    <PersonalSupport v-if="getDisplaySize() !== 'sm' && getDisplaySize() !== 'xs'"></PersonalSupport>


    <div class="my-16 py-4"></div>


  </div>
</template>

<script>
const FeatureHeader = () => import('../components/FeatureHeader.vue');
const FeatureList = () => import('../components/FeatureList.vue');
const FeatureListDetail = () => import('../components/FeatureListDetail.vue');
const FeatureForm = () => import('../components/FeatureForm.vue');
const PersonalSupport = () => import('../components/PersonalSupport.vue');
const FeatureHeaderMobile = () => import('../components/FeatureHeaderMobile.vue');
const FeatureListMobile = () => import('../components/FeatureListMobile.vue');
const FeatureListDetailMobile = () => import('../components/FeatureListDetailMobile.vue');
const PersonalSupportMobile = () => import('../components/PersonalSupportMobile.vue');

export default {
  name: "Features",
  components: {
    FeatureHeader,
    FeatureList,
    FeatureListDetail,
    FeatureForm,
    PersonalSupport,
    FeatureHeaderMobile,
    FeatureListMobile,
    FeatureListDetailMobile,
    PersonalSupportMobile
  },
  methods: {
    getDisplaySize(){
      return this.$vuetify.breakpoint.name;
    },
  }
}
</script>

<style scoped>

</style>